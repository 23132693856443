<template>
  <a href="tel:+79039065656">+7 903 906-56-56</a>
</template>

<script>
export default {
  name: "TelPrimary",
}
</script>

<style scoped>

</style>